import { LatLngExpression } from "leaflet";

export interface IDistributors {
  name: string;
  displayName?: string;
  description?: string;
  bt: string[];
  ufs: string[];
  url?: string;
  address?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  position?: LatLngExpression;
  center?: LatLngExpression;
  zoom?: number;
}

export const distributorsList: IDistributors[] = [
  {
    name: "CLASSICO COMERCIO DE REFEICOES RAPIDAS EIRELI",
    displayName: "Taverna Medieval",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.tavernamedieval.com.br/",
    description: "",
    address: "Rua Gandavo",
    number: "456",
    complement: "",
    neighborhood: "Vila Clementino",
    city: "São Paulo",
    state: "SP",
    zip: "04023-001",
    phone: "(11) 4114-2816",
  },
  {
    name: "BRUNA CAROLINE SILVA DE OLIVEIRA",
    displayName: "Behbibenn",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://behbibenn-taverna-pub.negocio.site/",
    description: "",
    address: "Rua Tuiuti",
    number: "2868",
    complement: "",
    neighborhood: "Tatuape",
    city: "São Paulo",
    state: "SP",
    zip: "03307-005",
    phone: "(11) 3938-7055",
  },
  {
    name: "TANIA MARGARETE LINHARES ISHII",
    displayName: "TK Taverna",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Thomaz Gonzaga",
    number: "28",
    complement: "",
    neighborhood: "Liberdade",
    city: "São Paulo",
    state: "SP",
    zip: "01506-020",
    phone: "(11) 3275-0751",
  },
  {
    name: "Cavaggioni & Previtalli Comercial Ltda ME",
    displayName: "Mr Tap - Cervejas Artesanais",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.mrtap.com.br/",
    description: "",
    address: "Rua Regente Feijo",
    number: "1314",
    complement: "",
    neighborhood: "Alemães",
    city: "Piracicaba",
    state: "SP",
    zip: "13419-290",
    phone: "(19) 3371-2630",
  },
  {
    name: "REI DA CERVEJA LTDA - ME",
    displayName: "Taberna do Rei",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.tabernadorei.com.br/cardapio/hidromeis",
    description: "",
    address: "Av. Pref. João Píres Filho",
    number: "15",
    complement: "",
    neighborhood: "Centro",
    city: "Santa Isabel",
    state: "SP",
    zip: "07500-000",
    phone: "(11) 9299-8890",
  },
  {
    name: "EMPORIO MAM-MOR COMERCIO DE PRODUTOS NATURAIS LTDA",
    displayName: "Empório Mam Mor",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.mammor.com.br/",
    description: "",
    address: "Rua Maria Tereza",
    number: "106",
    complement: "",
    neighborhood: "Jardim Santa Mena",
    city: "Guarulhos",
    state: "SP",
    zip: "07096-190",
    phone: "(11) 93244-8195",
  },
  {
    name: "JURGEN WILHELM MERKEL 22018143840",
    displayName: "Favo Dourado",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Alagoas",
    number: "47",
    complement: "",
    neighborhood: "Parque Residencial Alvorada",
    city: "Cacapava",
    state: "SP",
    zip: "12289-382",
    phone: "(12) 3655-5150",
  },
  {
    name: "Barbarus Burger",
    displayName: "Barbarus Burger - Hamburgueria Medieval",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.instagram.com/barbarusburgerfranca/",
    description: "",
    address: "Rua Marechal Caxias",
    number: "2593",
    complement: "",
    neighborhood: "Centro",
    city: "Franca",
    state: "SP",
    zip: "14400-600",
    phone: "(16) 9125-0860",
  },
  {
    name: "VIKINGS BURGUER LTDA ME",
    displayName: "Vikings Burguer",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.facebook.com/vikingsburguerhamburgueria/",
    description: "",
    address: "Rua Guilhermino Lima",
    number: "302",
    complement: "",
    neighborhood: "Vila Augusta",
    city: "Guarulhos",
    state: "SP",
    zip: "07040-090",
    phone: "(11) 2289-2226",
  },
  {
    name: "ROI BAR E RESTAURANTE LTDA",
    displayName: "ROI Méditerranée",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.instagram.com/roimediterranee/",
    description: "",
    address: "Rua Haddock Lobo",
    number: "1626",
    complement: "Loja 304.5",
    neighborhood: "Cerqueira Cesar",
    city: "São Paulo",
    state: "SP",
    zip: "01414-002",
    phone: "(11) 4040-7622",
  },
  {
    name: "MARCELO PAGIN 08897281605",
    displayName: "Maturare",
    bt: ["C"],
    ufs: ["MG"],
    url: "",
    description: "",
    address: "Praça Doutor Pedro Sanches",
    number: "S/N",
    complement: "loja D02 - Palace Hotel.",
    neighborhood: "Centro",
    city: "Poços de Caldas",
    state: "MG",
    zip: "37701-002",
    phone: "(35) 3721-6207",
  },
  {
    name: "CESAR CYRILLO LLORET 22022208870",
    displayName: "Safe House Board Games",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://safehousebg.com.br/",
    description: "",
    address: "Calçada das Hortências",
    number: "102",
    complement: "1º Andar",
    neighborhood: "Condominio Centro Comercial Alphaville",
    city: "Barueri",
    state: "SP",
    zip: "06453-017",
    phone: "(11) 97181-8332",
  },
  {
    name: "ZZB BAR E RESTAURANTE LTDA",
    displayName: "ZZ Bar",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Rua Barao Da Torre",
    number: "538",
    complement: "",
    neighborhood: "Ipanema",
    city: "Rio de Janeiro",
    state: "RJ",
    zip: "22411-002",
    phone: "(21) 2580-9515",
  },
  {
    name: "BNF COMERCIO DE BEBIDAS E ALIMENTOS LTDA",
    displayName: "Beer & Friends",
    bt: ["C"],
    ufs: ["CE"],
    url: "https://beernfriends.goomer.app/",
    description: "",
    address: "Av. Eusébio de Queiroz",
    number: "101",
    complement: "Loja 06",
    neighborhood: "Parnamirim",
    city: "Eusébio",
    state: "CE",
    zip: "61760-000",
    phone: "(85) 8181-9101",
  },
  {
    name: "BREWTECO GAVEA BAR E RESTAURANTE LTDA",
    displayName: "Brewteco",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Praça Santos Dumont",
    number: "106",
    complement: "",
    neighborhood: "Gávea",
    city: "Rio de Janeiro",
    state: "RJ",
    zip: "22470-060",
    phone: "(21) 9594-3642",
  },
  {
    name: "JOE ROCKER ALI E ENTR LTDA",
    displayName: "Joe Rocker",
    bt: ["C"],
    ufs: ["MG"],
    url: "",
    description: "",
    address: "Rua Joaquim Ferreira Braga",
    number: "118",
    complement: "",
    neighborhood: "Cazeca",
    city: "Uberlândia",
    state: "MG",
    zip: "38400-010",
    phone: "(34) 3239-6565",
  },
  {
    name: "JERONIMO JORGE DE OLIVEIRA PETISCARIA",
    displayName: "Valhala Taverna",
    bt: ["C"],
    ufs: ["PE"],
    url: "",
    description: "",
    address: "Av. Bernardo Vieira De Melo",
    number: "3310",
    complement: "Loja 08:09",
    neighborhood: "Piedade",
    city: "Jaboatão dos Guararapes",
    state: "PE",
    zip: "54400-000",
    phone: "(81) 98188-1811",
  },
  {
    name: "BYTOR COMERCIO VAREJISTA DE BEBIDAS E AFINS LTDA",
    displayName: "Fonte Tap Station",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Av. Das Americas",
    number: "3939",
    complement: "BL 1 LJ Q",
    neighborhood: "Barra Da Tijuca",
    city: "Rio de Janeiro",
    state: "RJ",
    zip: "22631-003",
    phone: "(21) 2146-3050",
  },
  {
    name: "GORILA BARBER",
    displayName: "Gorila Barber",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Jacareí",
    number: "304",
    complement: "",
    neighborhood: "Vila Rosália",
    city: "Guarulhos",
    state: "SP",
    zip: "07464-140",
    phone: "(11) 2937-5449",
  },
  {
    name: "BROTHERHOOD STEACK BAR",
    displayName: "Brotherhood",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Soldado Adão Wojcik",
    number: "65",
    complement: "",
    neighborhood: "Jd. Maria Helena",
    city: "Guarulhos",
    state: "SP",
    zip: "07115-280",
    phone: "(11) 3014-4901",
  },
  {
    name: "DOM VITO BARBEARIA E CHOPERIA LTDA",
    displayName: "Dom Vito",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Salgado Filho",
    number: "552",
    complement: "",
    neighborhood: "Centro",
    city: "Guarulhos",
    state: "SP",
    zip: "07115-000",
    phone: "(11) 3820-0076",
  },
  {
    name: "OFICINA DA CERVEJA",
    displayName: "Oficina da Cerveja",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua João Francisco Ferreirinha",
    number: "130",
    complement: "",
    neighborhood: "Vila Acoreana",
    city: "Poá",
    state: "SP",
    zip: "08557-350",
    phone: "(11) 4638-8648",
  },
  {
    name: "FAURO E MACEDO COMERCIAL EIRELI",
    displayName: "All Bebidas 2",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Washington Luis",
    number: "4670",
    complement: "",
    neighborhood: "Santo Amaro",
    city: "São Paulo",
    state: "SP",
    zip: "04626-000",
    phone: "(11) 5521-6028",
  },
  {
    name: "FAURO E MACEDO C0MERCIAL EIRELI",
    displayName: "All Bebidas 1",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Atlantica",
    number: "6449",
    complement: "",
    neighborhood: "Parque Atlântico",
    city: "São Paulo",
    state: "SP",
    zip: "04805-000",
    phone: "(11) 2367-2066",
  },
  {
    name: "MARINO & MARINO COM VAREJISTA DE ALIMENTOS LTDA",
    displayName: "Emporio Marino",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Winston Churchill",
    number: "919",
    complement: "",
    neighborhood: "Rudge Ramos",
    city: "São Bernardo do Campo",
    state: "SP",
    zip: "09614-000",
    phone: "(11) 3996-2965",
  },
  {
    name: "EMPORIO FLOR DE CAFE",
    displayName: "Emporio Flor do Café",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Do Cafe",
    number: "748",
    complement: "",
    neighborhood: "Vila Tibério",
    city: "Ribeirao Preto",
    state: "SP",
    zip: "14050-220",
    phone: "(16) 99199-9597",
  },
  {
    name: "VILA DO GRAO COMERCIO DE ALIMENTOS LTDA",
    displayName: "Vila do Grão",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Doutor Heitor Penteado",
    number: "2071",
    complement: "",
    neighborhood: "Parque Taquaral",
    city: "Campinas",
    state: "SP",
    zip: "13087-000",
    phone: "(19) 3255-5217",
  },
  {
    name: "ALINE ELBERT",
    displayName: "Apiário Art Mel Loja",
    bt: ["C"],
    ufs: ["SC"],
    url: "",
    description: "",
    address: "Rodovia SC-370",
    number: "1",
    complement: "",
    neighborhood: "Santo Antônio",
    city: "Urubici",
    state: "SC",
    zip: "88650-000",
    phone: "(49) 3278-5136",
  },
  {
    name: "Emporio D Esquina",
    displayName: "Emporio D Esquina",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Rio Capibaribe",
    number: "20",
    complement: "",
    neighborhood: "Jardim Jockei Club A",
    city: "São Carlos",
    state: "SP",
    zip: "13565-230",
    phone: "(16) 99748-4043",
  },
  {
    name: "ZIGGY HOSTEL CLUB LTA - ME",
    displayName: "Valhala Taverna",
    bt: ["C"],
    ufs: ["PA"],
    url: "",
    description: "",
    address: "Travessa Benjamim Constant",
    number: "1329",
    complement: "",
    neighborhood: "Nazaré",
    city: "Belém",
    state: "PA",
    zip: "66035-060",
    phone: "(91) 3085-4909",
  },
  {
    name: "KROMANN COMERCIO DE ALIMENTOS E BEBIDAS LTDA",
    displayName: "Krommann",
    bt: ["C"],
    ufs: ["RS"],
    url: "",
    description: "",
    address: "Rua Pedro Schneider",
    number: "155",
    complement: "",
    neighborhood: "Centro",
    city: "Rolante",
    state: "RS",
    zip: "95690-000",
    phone: "(51) 3543-8700",
  },
  {
    name: "MARIA DA CONCEICAO TEIXEIRA ME",
    displayName: "Armazén São Judas",
    bt: ["C"],
    ufs: ["MG"],
    url: "",
    description: "",
    address: "Rua Borba Gato",
    number: "13",
    complement: "",
    neighborhood: "Centro",
    city: "Sabará",
    state: "MG",
    zip: "34505-830",
    phone: "(31) 99685-2875",
  },
  {
    name: "BRUNO MARGULHANO 22709262851",
    displayName: "Florence Empório",
    bt: ["C"],
    ufs: ["SC"],
    url: "",
    description: "",
    address: "Av. Osmar De Souza Nunes",
    number: "271",
    complement: "Sala 01",
    neighborhood: "Pioneiros",
    city: "Balneario Camboriu",
    state: "SC",
    zip: "88331-070",
    phone: "(47) 99283-3003",
  },
  {
    name: "LEILA CELBIA CARNEIRO CARDOSO LYRA GONCALVES 48331449568",
    displayName: "Empório Frigga",
    bt: ["C"],
    ufs: ["BA"],
    url: "",
    description: "",
    address: "Av. Praia de Itapua",
    number: "784",
    complement: "Shopping Canoas, Loja 103, 1 andar",
    neighborhood: "Vilas do Atlantico",
    city: "Lauro de Freitas",
    state: "BA",
    zip: "42707-650",
    phone: "(71) 98810-1228",
  },
  {
    name: "SANTO EMPÓRIO",
    displayName: "Santo Empório",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Rua das Laranjeiras",
    number: "29",
    complement: "Lj 228",
    neighborhood: "Laranjeiras",
    city: "Rio de Janeiro",
    state: "RJ",
    zip: "22240-000",
    phone: "(21) 98830-9097",
  },
  {
    name: "PASOLI GOURMET PROD. ALIMENTICIOS LTDA",
    displayName: "Casa da Azeitona",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Rua Dona Mariana",
    number: "91",
    complement: "LJ A/B",
    neighborhood: "Botafogo",
    city: "Rio de Janeiro",
    state: "RJ",
    zip: "22280-020",
    phone: "(21) 3085-1488",
  },
  {
    name: "Dioneia de Lourdes Colombo - cpf.154.451.028-40",
    displayName: "Senhora Cerveja",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Presidente Humberto De Alencar Castelo Branco",
    number: "2808",
    complement: "apto 75",
    neighborhood: "Vila Antonieta",
    city: "Guarulhos",
    state: "SP",
    zip: "07040-030",
    phone: "(11) 2414-3718",
  },
  {
    name: "EDSON JOSE DE SOUZA",
    displayName: "Cachaçaria Uberaba",
    bt: ["C"],
    ufs: ["MG"],
    url: "",
    description: "",
    address: "Praça Manoel Terra",
    number: "255",
    complement: "Box 19/20",
    neighborhood: "Centro",
    city: "Uberaba",
    state: "MG",
    zip: "38025-015",
    phone: "(34) 9687-5257",
  },
  {
    name: "SAUDE VITTA PRODUTOS NATURAIS",
    displayName: "Saude Vitta",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Vereador Sebastião Claudio",
    number: "76",
    complement: "Galpão 01 e 02",
    neighborhood: "Vila Nova",
    city: "Santa Izabel",
    state: "SP",
    zip: "07500-000",
    phone: "(11) 4556-3181",
  },
  {
    name: "EMPORIO GIROTO",
    displayName: "Giroto",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Dr Renato de Andrade Maia",
    number: "742",
    complement: "",
    neighborhood: "Parque Renato Maia",
    city: "Guarulhos",
    state: "SP",
    zip: "07114-000",
    phone: "(11) 2472-1010",
  },
  {
    name: "CASA ARIAS",
    displayName: "Casa Arias",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Melchert",
    number: "938",
    complement: "",
    neighborhood: "Chácara Seis de Outubro",
    city: "São Paulo",
    state: "SP",
    zip: "03508-000",
    phone: "(11) 2385-0700",
  },
  {
    name: "CASA ARIAS",
    displayName: "Casa Arias",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Eleonora Cintra",
    number: "335",
    complement: "",
    neighborhood: "Tatuapé",
    city: "São Paulo",
    state: "SP",
    zip: "03337-000",
    phone: "(11) 2385-0700",
  },
  {
    name: "MERCEARIA MONTEALEGRENSE",
    displayName: "Mercearia Montealegrense",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Marcondes de Brito",
    number: "1484",
    complement: "",
    neighborhood: "Chácara Seis de Outubro",
    city: "São Paulo",
    state: "SP",
    zip: "03509-000",
    phone: "(11) 2651-7045",
  },
  {
    name: "SUPERMERCADO BROTAS LTDA",
    displayName: "Supermercado Brotas",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Prefeito Jose Basilio Alvarenga",
    number: "1171",
    complement: "",
    neighborhood: "Brotas",
    city: "Santa Isabel",
    state: "SP",
    zip: "07500-000",
    phone: "(11) 4656-2187",
  },
  {
    name: "SUPERMERCADO DELTA MAX LTDA",
    displayName: "Delta Supermercados",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Sebastiao Moretti",
    number: "303",
    complement: "",
    neighborhood: "Santa Rita",
    city: "Piracicaba",
    state: "SP",
    zip: "13423-212",
    phone: "(19) 3429-0210",
  },
  {
    name: "GREEN FRUIT NOVO MILENIO HORTIFRUTIGRANJEIROS LTDA",
    displayName: "Green Fruit",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "Av. Presidente Roosevelt",
    number: "116",
    complement: "",
    neighborhood: "Barra Do Imbui",
    city: "Teresopolis",
    state: "RJ",
    zip: "25966-000",
    phone: "(21) 2641-7612",
  },
  {
    name: "SUPERMERCADOS MONTEIRO",
    displayName: "Supermercados Monteiro",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Capitão Alberto Aguiar Weissohn",
    number: "131",
    complement: "",
    neighborhood: "Centro",
    city: "Guararema",
    state: "SP",
    zip: "08900-000",
    phone: "(11) 4693-3434",
  },
  {
    name: "SUPERMERCADO PREÇO & CIA - MATRIZ",
    displayName: "Preço & Cia",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Maria Carlota",
    number: "577",
    complement: "",
    neighborhood: "Vila Esperança",
    city: "São Paulo",
    state: "SP",
    zip: "03647-000",
    phone: "(11) 3807-3850",
  },
  {
    name: "SUPERMERCADO PREÇO & CIA - MATRIZ",
    displayName: "Preço & Cia",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Tuiuti",
    number: "904",
    complement: "",
    neighborhood: "Tatuapé",
    city: "São Paulo",
    state: "SP",
    zip: "03081-015",
    phone: "(11) 2581-4800",
  },
  {
    name: "SUPERMERCADO ESTRELA AZUL",
    displayName: "Estrela Azul",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Praça Porto Ferreira",
    number: "48",
    complement: "",
    neighborhood: "Vila Guilhermina",
    city: "São Paulo",
    state: "SP",
    zip: "03542-070",
    phone: "(11) 2023-9090",
  },
  {
    name: "SUPERMERCADO ESTRELA AZUL",
    displayName: "Estrela Azul",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Av. Antonio Estevão de Carvalho",
    number: "1532",
    complement: "",
    neighborhood: "Cidade Patriarca",
    city: "São Paulo",
    state: "SP",
    zip: "03540-400",
    phone: "(11) 2023-6350",
  },
  {
    name: "SUPERMERCADO STYLLUS",
    displayName: "Supermercado Styllus",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.styllussupermercados.com.br/",
    description: "",
    address: "Rua Major Benjamim franco",
    number: "555",
    complement: "",
    neighborhood: "Centro",
    city: "Arujá",
    state: "SP",
    zip: "07400-165",
    phone: "(11) 4653-3075",
  },
  {
    name: "J2L COMERCIO DE PRODUTOS ARTESANAIS LTDA ME",
    displayName: "Major Birita",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://majorbirita.com.br/hidromel",
    description: "",
    address: "Rua Joao Pereira",
    number: "115",
    complement: "",
    neighborhood: "Vila Pereira",
    city: "Itapira",
    state: "SP",
    zip: "13970-435",
    phone: "(19) 4126-0149",
  },
  {
    name: "AMAZON SERVICOS DE VAREJO DO BRASIL LTDA.",
    displayName: "Amazon",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.amazon.com.br/s?k=HIDROMEL&",
    description: "",
    address: "Av. Antonio Candido Machado",
    number: "3100",
    complement: "",
    neighborhood: "Jordanesia",
    city: "Cajamar",
    state: "SP",
    zip: "07776-415",
    phone: "(11) 4130-2000",
  },
  {
    name: "EMPORIO MAM-MOR COMERCIO DE PRODUTOS NATURAIS LTDA",
    displayName: "Empório Mam Mor",
    bt: ["C"],
    ufs: ["SP"],
    url: "https://www.mammor.com.br/loja",
    description: "",
    address: "Rua Maria Tereza",
    number: "106",
    complement: "",
    neighborhood: "Jardim Santa Mena",
    city: "Guarulhos",
    state: "SP",
    zip: "07096-190",
    phone: "(11) 4574-1446",
  },
  {
    name: "Percinilda Santos Rodrigues",
    displayName: "Cellshop",
    bt: ["C"],
    ufs: ["SC"],
    url: "https://www.cellshopbebidas.com.br/HIDROMEL",
    description: "",
    address: "Rua Yolanda Pinto Cabral",
    number: "341",
    complement: "",
    neighborhood: "Cordeiros",
    city: "Itajai",
    state: "SC",
    zip: "88310-310",
    phone: "(47) 2033-7236",
  },
  {
    name: "FAB COMERCIO VAREJISTA DE BEBIDAS EIRELI",
    displayName: "FAB",
    bt: ["C"],
    ufs: ["SC"],
    url: "https://www.fabricadebebidas.com.br/hidromel",
    description: "",
    address: "Rua Dona Elza Meinert",
    number: "68",
    complement: "",
    neighborhood: "Costa E Silva",
    city: "Joinville",
    state: "SC",
    zip: "89218-650",
    phone: "(47) 3028-2699",
  },
  {
    name: "INFOMIX ATACADO DE VAREJO LTDA",
    displayName: "BH Entrega",
    bt: ["C"],
    ufs: ["MG"],
    url: "https://www.bhentrega.com.br/bebidas/old-pony",
    description: "",
    address: "Rua Borges",
    number: "276",
    complement: "Letra A",
    neighborhood: "Indaia",
    city: "Belo Horizonte",
    state: "MG",
    zip: "31270-150",
    phone: "(31) 3427-7629",
  },
  {
    name: "NOSSO GOLE",
    displayName: "Nosso Gole",
    bt: ["C"],
    ufs: ["RS"],
    url: "https://www.nossogole.com.br/oldpony",
    description: "",
    address: "Rua Cel. João Correa",
    number: "475",
    complement: "",
    neighborhood: "Centro",
    city: "Parobé",
    state: "RS",
    zip: "95630-000",
    phone: "(51) 99645-0756",
  },
  {
    name: "THALLYS THIERES",
    displayName: "",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Araruama",
    state: "RJ",
    zip: "",
    phone: "(22) 99912-7912",
  },
  {
    name: "IASMIM RODRIGUES",
    displayName: "SKAL",
    bt: ["C"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Carapicuíba",
    state: "SP",
    zip: "",
    phone: "(11) 3164-1784",
  },
  {
    name: "Ana Carolina de Mello Cavalcante do Vale",
    displayName: "Drynkability",
    bt: ["C"],
    ufs: ["AM"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Manaus",
    state: "AM",
    zip: "",
    phone: "(92) 9464-4706",
  },
  {
    name: "ERICK ERON BECHER 08849403909",
    displayName: "De Ville",
    bt: ["C"],
    ufs: ["PR"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Ponta Grossa",
    state: "PR",
    zip: "",
    phone: "(42) 9997-1839",
  },
  {
    name: "MWG TABERNA - COMERCIO DE ALIMENTOS LTDA",
    displayName: "",
    bt: ["C"],
    ufs: ["DF"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Brasilia",
    state: "DF",
    zip: "",
    phone: "(61) 3202-5858",
  },
  {
    name: "TONS & TACAS VINHOS E ETC",
    displayName: "",
    bt: ["C"],
    ufs: ["PR"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Curitiba",
    state: "PR",
    zip: "",
    phone: "(41) 99224-9803",
  },
  {
    name: "DANIEL CAMPOS DE MIRANDA",
    displayName: "Corvus Nordic Beer",
    bt: ["C"],
    ufs: ["MG"],
    url: "https://www.facebook.com/corvusnordicbeer/",
    description: "",
    address: "Rua São Mateus",
    number: "47",
    complement: "",
    neighborhood: "São Mateus",
    city: "Juiz de Fora",
    state: "MG",
    zip: "36025-001",
    phone: "(32) 99120-1832",
  },
  {
    name: "JULIA GAZZONI JARDIM",
    displayName: "",
    bt: ["C"],
    ufs: ["RJ"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Campos dos Goytacazes",
    state: "RJ",
    zip: "",
    phone: "(41) 99171-2324",
  },
  {
    name: "BRUNO DE ASSIS SIMÕES",
    displayName: "Casa Marchi",
    bt: ["C"],
    ufs: ["MG"],
    url: "",
    description: "",
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "Uberaba",
    state: "MG",
    zip: "",
    phone: "(34) 99244-0034",
  },
  {
    name: "DISTRIBUIDORA DE BEBIDAS TM LTDA",
    displayName: "Dádiva Distribuidora (linha Session)",
    bt: ["B"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Paschoal Gimene",
    number: "35",
    complement: "",
    neighborhood: "Sítio São José",
    city: "Varzea Paulista",
    state: "SP",
    zip: "13225-325",
    phone: "(11) 94316-0833",
  },
  {
    name: "FAST VENDAS SUPERMERCADO LTDA - ME",
    displayName: "Newness",
    bt: ["B"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Antonio Ravagnani",
    number: "80",
    complement: "",
    neighborhood: "Vila São João",
    city: "Mogi Mirim",
    state: "SP",
    zip: "13800-665",
    phone: "(19) 3806-2623",
  },
  {
    name: "RP GASTRO DISTRIBUIDORA DE BEBIDA LTDA",
    displayName: "Confraria Paulistânia",
    bt: ["B"],
    ufs: ["SP"],
    url: "",
    description: "",
    address: "Rua Cachoeira",
    number: "942",
    complement: "",
    neighborhood: "Jardim Rosa De Franca",
    city: "Guarulhos",
    state: "SP",
    zip: "07080-000",
    phone: "(11) 2447-1865",
  },
  {
    name: "NOSSO GOLE",
    displayName: "NOSSO GOLE",
    bt: ["B"],
    ufs: ["RS"],
    url: "",
    description: "",
    address: "Rua Cel. João Correa",
    number: "475",
    complement: "",
    neighborhood: "Centro",
    city: "Parobé",
    state: "RS",
    zip: "95630-000",
    phone: "(51) 99645-0756",
  },
];
