import { LatLngExpression } from "leaflet";

interface IUFMarkers {
  code: string;
  name: string;
  position: LatLngExpression;
  center: LatLngExpression;
  zoom: number;
}

export const ufMarkers: IUFMarkers[] = [
  {
    code: "AC",
    name: "Acre",
    position: [-8.77, -70.55],
    center: [-8.77, -72.55],
    zoom: 7,
  },
  {
    code: "AP",
    name: "Amapá",
    position: [1.57, -51.94],
    center: [1.41, -53.77],
    zoom: 7,
  },
  {
    code: "AL",
    name: "Alagoas",
    position: [-9.45, -36.63],
    center: [-9.71, -37.65],
    zoom: 8,
  },
  {
    code: "AM",
    name: "Amazonas",
    position: [-3.9, -64.7],
    center: [-3.8, -68.45],
    zoom: 6,
  },
  {
    code: "BA",
    name: "Bahia",
    position: [-11.5, -42.0445],
    center: [-12.96, -42.2],
    zoom: 6,
  },
  {
    code: "CE",
    name: "Ceará",
    position: [-4.7, -39.6],
    center: [-5.2, -41.6],
    zoom: 7,
  },
  {
    code: "DF",
    name: "Distrito Federal",
    position: [-15.77, -47.877],
    center: [-15.77, -48.05],
    zoom: 10,
  },
  {
    code: "ES",
    name: "Espírito Santo",
    position: [-19.085, -40.325],
    center: [-19.085, -44],
    zoom: 8,
  },
  {
    code: "GO",
    name: "Goiás",
    position: [-15.78, -49.56],
    center: [-15.8, -50],
    zoom: 6,
  },
  {
    code: "MA",
    name: "Maranhão",
    position: [-4.8, -45.25],
    center: [-5.7, -45.7],
    zoom: 6,
  },
  {
    code: "MT",
    name: "Mato Grosso",
    position: [-13.235, -55.94],
    center: [-13.235, -56.5],
    zoom: 6,
  },
  {
    code: "MS",
    name: "Mato Grosso do Sul",
    position: [-19.05, -54.95],
    center: [-20, -55.5],
    zoom: 6,
  },
  {
    code: "MG",
    name: "Minas Gerais",
    position: [-17.85, -44.28],
    center: [-18.5, -44.28],
    zoom: 6,
  },
  {
    code: "PA",
    name: "Pará",
    position: [-3.8, -53.11],
    center: [-4, -53],
    zoom: 6,
  },
  {
    code: "PB",
    name: "Paraíba",
    position: [-7.045, -36.802],
    center: [-7.085, -39.84],
    zoom: 8,
  },
  {
    code: "PR",
    name: "Paraná",
    position: [-24.1, -51.55],
    center: [-24.9, -53.5],
    zoom: 7,
  },
  {
    code: "PE",
    name: "Pernambuco",
    position: [-8.28, -38.18],
    center: [-8.3, -39.895],
    zoom: 7,
  },
  {
    code: "PI",
    name: "Piauí",
    position: [-7.15, -43.005],
    center: [-7.15, -42.7],
    zoom: 6,
  },
  {
    code: "RJ",
    name: "Rio de Janeiro",
    position: [-21.93, -42.16],
    center: [-22.2, -46.15],
    zoom: 8,
  },
  {
    code: "RN",
    name: "Rio Grande do Norte",
    position: [-5.65, -36.755],
    center: [-5.85, -37.755],
    zoom: 8,
  },
  {
    code: "RS",
    name: "Rio Grande do Sul",
    position: [-28.8, -53.7],
    center: [-30, -55.7],
    zoom: 7,
  },
  {
    code: "RO",
    name: "Rondônia",
    position: [-9.9, -63.39],
    center: [-11.02, -65.5],
    zoom: 7,
  },
  {
    code: "RR",
    name: "Roraima",
    position: [2.22, -61.39],
    center: [1.925, -63.65],
    zoom: 7,
  },
  {
    code: "SC",
    name: "Santa Catarina",
    position: [-26.85, -50.54],
    center: [-27.33, -53.3],
    zoom: 7,
  },
  {
    code: "SP",
    name: "São Paulo",
    position: [-21.8, -49.06],
    center: [-22.55, -50.64],
    zoom: 7,
  },
  {
    code: "SE",
    name: "Sergipe",
    position: [-10.395, -37.315],
    center: [-10.595, -40.45],
    zoom: 8,
  },
  {
    code: "TO",
    name: "Tocantins",
    position: [-9.88, -48.305],
    center: [-10.25, -49.05],
    zoom: 6,
  },
];