import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Form, Select, Tabs } from "antd";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import L, { LatLngExpression, Map } from "leaflet";

import { ufMarkers } from "../../utils/general";
import { distributorsList, IDistributors } from "../../utils/distributors";

import mapMarkerImg from "../../assets/images/map-marker.svg";
import opLogoImg from "../../assets/images/logo-branco.svg";

import "./styles.css";

const { Option } = Select;
const { TabPane } = Tabs;

const mapIcon = L.icon({
  iconUrl: mapMarkerImg,

  iconSize: [47, 68],
  iconAnchor: [23.5, 68],
  popupAnchor: [170, 2],
});

interface IDisplayPosition {
  map: Map;
  btbList: IDistributors[] | null;
  btcList: IDistributors[] | null;
}

function DisplayPosition({ map, btbList, btcList }: IDisplayPosition) {
  let history = useHistory();
  //const [position, setPosition] = useState(map.getCenter());

  //const onClick = useCallback(() => {
  //  map.setView(center, zoom);
  //}, [map]);

  //const onMove = useCallback(() => {
  //  setPosition(map.getCenter());
  //}, [map]);

  //useEffect(() => {
  //  map.on("move", onMove);
  //  return () => {
  //    map.off("move", onMove);
  //  };
  //}, [map, onMove]);

  return (
    <div>
      <div id="side-container-top">
        <img src={opLogoImg} alt="OldPony Meadery" width={128} height={96} />
      </div>
      <div id="side-container-body">
        <header>
          <h2>Onde Encontrar?</h2>
        </header>
        <main>
          <Form layout="vertical">
            <Form.Item>
              <Select<string>
                placeholder="Escolha o seu Estado"
                optionFilterProp="children"
                onChange={(e) => history.push(`/estado/${e}`)}
                style={{ width: "100%" }}
              >
                {ufMarkers.map((item) => {
                  return (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
          {((btcList && btcList.length > 0) ||
            (btbList && btbList.length > 0)) && (
            <Tabs type="card">
              <TabPane
                tab="Consumo"
                key="B2C"
                style={{
                  height: "calc(100vh - 254px)",
                  overflow: "auto",
                }}
              >
                {btcList &&
                  btcList.length > 0 &&
                  btcList.map((item, index) => {
                    return (
                      <Card
                        key={`btc-${index}`}
                        size="small"
                        title={item.displayName || item.name}
                        extra={
                          item.url && (
                            <a href={item.url} target="_blank" rel="noreferrer">
                              site
                            </a>
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <p>
                          {item.address}
                          {item?.number && `, ${item.number}`}
                          {item?.complement && ` - ${item.complement}`}
                        </p>
                        <p>
                          {item.neighborhood}
                          {item?.city && ` - ${item.city}`}
                          {item?.state && ` - ${item.state}`}
                        </p>
                        <div className="zipPhone">
                          {item?.zip && <p>CEP: {item.zip}</p>}
                          {item?.phone && <p>Tel: {item.phone}</p>}
                        </div>
                      </Card>
                    );
                  })}
              </TabPane>
              <TabPane
                tab="Revenda"
                key="B2B"
                style={{
                  height: "calc(100vh - 254px)",
                  overflow: "auto",
                }}
              >
                {btbList &&
                  btbList.length > 0 &&
                  btbList.map((item, index) => {
                    return (
                      <Card
                        key={`btb-${index}`}
                        size="small"
                        title={item.name}
                        extra={
                          item.url && (
                            <a href={item.url} target="_blank" rel="noreferrer">
                              site
                            </a>
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <p>
                          {item.address}
                          {item?.number && `, ${item.number}`}
                          {item?.complement && ` - ${item.complement}`}
                        </p>
                        <p>
                          {item.neighborhood}
                          {item?.city && ` - ${item.city}`}
                          {item?.state && ` - ${item.state}`}
                        </p>
                        <p>
                          CEP: {item.zip}
                          {item?.phone && (
                            <span className="phone">Tel: {item.phone}</span>
                          )}
                        </p>
                      </Card>
                    );
                  })}
              </TabPane>
            </Tabs>
          )}
          {(!btcList || btcList.length === 0) &&
            (!btbList || btbList.length === 0) && (
              <p className="sub">
                Infelizmente ainda não temos distribuidores neste estado.
              </p>
            )}
        </main>
      </div>
    </div>
  );
}

interface IParams {
  uf?: string;
  cep?: string;
}

export default function DistributorsMap() {
  const { uf }: IParams = useParams();
  const [map, setMap] = useState<Map | null>(null);
  const [center, setCenter] = useState<LatLngExpression>([-13, -62.3]);
  const [zoom, setZoom] = useState(5);

  const [btbList, setBtBList] = useState<IDistributors[] | null>(null);
  const [btcList, setBtCList] = useState<IDistributors[] | null>(null);

  useEffect(() => {
    if (uf && uf.length > 0) {
      ufMarkers
        .filter((elf) => elf.code.toLowerCase() === uf.toLowerCase())
        .forEach((el) => {
          setZoom(el.zoom);
          setCenter(el.center);
        });

      var newBtCList = distributorsList
        .filter(
          (elf) => elf.ufs.includes(uf.toUpperCase()) && elf.bt.includes("C")
        )
        .map((el) => {
          return el;
        })
        .sort((a, b) =>
          (a.displayName || a.name).localeCompare(b.displayName || b.name)
        );

      setBtCList(newBtCList);

      var newBtBList = distributorsList
        .filter(
          (elf) => elf.ufs.includes(uf.toUpperCase()) && elf.bt.includes("B")
        )
        .map((el) => {
          return el;
        })
        .sort((a, b) =>
          (a.displayName || a.name).localeCompare(b.displayName || b.name)
        );

      setBtBList(newBtBList);
    } else {
      console.log("inicio");
    }
  }, [uf]);

  useEffect(() => {
    console.log("dist", { btcList });
  }, [btcList]);

  const displayMap = useMemo(
    () => (
      <MapContainer
        style={{ width: "100%", height: "100%" }}
        zoomControl={false}
        scrollWheelZoom={true}
        doubleClickZoom={true}
        whenCreated={setMap}
      >
        <ChildZoom center={center} zoom={zoom} />
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
        />
        {ufMarkers.map((item) => {
          return (
            <Marker key={item.code} icon={mapIcon} position={item.position}>
              <Popup
                closeButton={false}
                minWidth={240}
                maxWidth={240}
                className="map-popup"
              >
                {item.name}
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    ),
    [center, zoom]
  );

  return (
    <div id="page-map">
      <div id="map-container">{displayMap}</div>
      <div id="side-container">
        {map ? (
          <DisplayPosition map={map} btbList={btbList} btcList={btcList} />
        ) : null}
      </div>
    </div>
  );
}

function ChildZoom({
  center,
  zoom,
}: {
  center: LatLngExpression;
  zoom: number;
}) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}
